<script setup lang="ts">
import { Category } from '@shopware-pwa/types';

const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const sidebarController = useModal();

const isMenuActive = ref<boolean | null>(false);
const isSearchActive = ref<boolean | null>(false);
const activeNavElem = ref<Category | null>(null);
const activeChildElem = ref<Category | null>(null);

// const promobar = getTranslatedProperty(null, 'customFields')
</script>
<template>
  <header
    class="top-bar fixed w-full z-200 max-w-superlarge left-1/2 -translate-x-1/2"
    aria-label="top-navigation"
  >
    <div
      class="backdrop fixed w-full h-screen bg-dark/40 z-100"
      aria-hidden="true"
      v-if="isMenuActive || isSearchActive"
      @click="
        isMenuActive = false;
        isSearchActive = false;
      "
    >
      <button
        class="hidden rounded-full bg-white w-11 h-11 md:flex justify-center items-center text-dark absolute left-1/2 top-15 -translate-x-[150%]"
      >
        <span class="sr-only"> Close filter </span>
        <i aria-hidden class="block icon i-custom-svg:cross text-2xl"></i>
      </button>
    </div>
    <div
      class="header-inner mx-auto px-4 sm:px-6 lg:pt-15 lg:px-15 flex items-center py-6 space-x-4 w-full"
    >
      <div class="flex justify-start items-center min-w-10 lg:min-w-12">
        <div class="order-2 lg:order-1 ml-4 lg:ml-0">
          <NuxtLink :to="formatLink(`/`)">
            <span class="sr-only">SN super.natural Online Shop</span>
            <img v-lazy-load 
              class="h-10 w-10 lg:h-12 lg:w-12"
              data-src="/logo/sn_logo_schwarz.svg"
              alt="SN Logo"
              width="40"
              height="40"
              fetchpriority="high"
            />
          </NuxtLink>
        </div>
        <div class="order-1 lg:order-2 flex justify-start items-center">
          <LayoutSideMenu @open-cart-menu="sidebarController.open"/>
        </div>
      </div>
      <NuxtLink :to="formatLink(`/`)" class="absolute left-1/2 -translate-x-1/2 !ml-0 z-300 md:hidden">
        <span class="sr-only">SN super.natural Online Shop</span>
        <img v-lazy-load 
          class="h-10 w-10 lg:h-12 lg:w-12"
          data-src="/logo/sn_logo_schwarz.svg"
          alt="SN Logo"
          width="40"
          height="40"
          fetchpriority="high"
        />
      </NuxtLink>
      <div class="hidden md:flex flex-1" aria-hidden="true"></div>
      <div
        class="top-navigation w-full lg:w-auto 2xl:w-[calc(50%-3.81rem)] absolute right-0 lg:right-15"
      >
        <div
          class="navigation-bar bg-white rounded-full px-6 py-4 flex items-center justify-end lg:justify-between relative z-200 mx-2"
        >
          <LayoutTopNavigation
            :isMenuActive="isMenuActive"
            @openMenu="isMenuActive = true"
            @closeMenu="isMenuActive = false"
            @setActiveNav="((navElem: Category) => activeNavElem = navElem )"
            @setActiveChildNav="() => (activeChildElem = null)"
          />

          <div class="flex items-center justify-end space-x-8">
            <!-- Search -->
            <div class="hidden lg:flex">
              <button
                class="group bg-transparent flex items-center relative"
                @click="isSearchActive = !isSearchActive"
              >
                <div
                  class="i-carbon-search w-6 h-6 text-dark hover:text-brand-primary"
                />
                <span class="sr-only"
                  >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
                >
              </button>
            </div>
            <!-- Wishlist -->
            <div class="hidden lg:flex">
              <NuxtLink
                class="group flex items-center relative text-center"
                aria-label="wishlist"
                data-testid="wishlist-button"
                :to="formatLink(`/wishlist`)"
              >
                <div
                  :class="
                    wishlistCount > 0
                      ? 'i-custom-svg:heart-filled'
                      : 'i-custom-svg:heart'
                  "
                  class="w-6 h-6 text-dark hover:text-brand-primary"
                />
                <span
                  v-if="wishlistCount > 0"
                  class="text-3 pt-1 font-sm text-white absolute w-6 h-6 text-center flex items-center justify-center"
                >
                  {{ wishlistCount > 100 ? '99+' : wishlistCount || '' }}
                </span>
              </NuxtLink>
            </div>
            <!-- Account -->
            <template class="hidden lg:flex">
              <AccountMenu />
            </template>
            <!-- Cart -->
            <div class="flex">
              <button
                class="group bg-transparent flex items-center relative"
                aria-label="Mini cart"
                data-testid="cart-button"
                @click="sidebarController.open"
              >
                <!-- Heroicon name: outline/shopping-bag -->
                <div
                  :class="
                    count > 0 ? 'i-custom-svg:cart-filled' : 'i-custom-svg:cart'
                  "
                  class="w-6 h-6 text-dark hover:text-brand-primary"
                />
                <span
                  v-if="count > 0"
                  class="text-3 pt-2 font-sm text-white absolute w-6 h-6 text-center flex items-center justify-center"
                >
                  {{ count > 100 ? '99+' : count || '' }}
                </span>
                <span class="sr-only"
                  >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <CheckoutSideCart :controller="sidebarController" />
        <LayoutFullNavigation
          :isMenuActive="isMenuActive"
          :activeNavElem="activeNavElem"
          :activeChildElem="activeChildElem"
          @setActiveChildMenu="(navElem: Category) => activeChildElem = navElem"
          @closeMenu="isMenuActive = false"
        />
        <LayoutStoreSearch
          :isSearchActive="isSearchActive"
          @closeSearch="isSearchActive = false"
        />
      </div>
      <SharedPromoBanner />
    </div>
  </header>
</template>
